import React from 'react'

import { useTranslation } from './IntlContext/IntlContext'
import useClasses from '../core/useClasses'


const styles = theme => ({
    table: {
        margin: '10px 200px',
        minWidth: '300px'
    },
    tableHeader: {
        width: '100%',
        borderBottom: 'solid 2px rgba(0,0,0,0.5)',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        '& span': {
            fontWeight: 'bold',
            fontSize: 15
        }
    },
    tableProduct: {
        margin: 0,
        marginTop: 20,
        marginBottom: 30,
        border: 'solid 2px rgba(0,0,0,0.125)'
    },
    tableRows: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        '& > div': {
            padding: 5,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            border: '1px solid rgba(0,0,0,0.19)',
            borderStyle: 'solid',
            borderWidth: '1px 1px 0px 1px',
            '& > span': {
                marginLeft: 10,
                marginRight: 10
            },
            '& .bold': {
                fontWeight: 'bold'
            }
        },
        '& > div:last-child': {
            border: '1px solid rgba(0,0,0,0.19)',
            borderStyle: 'solid',
            borderWidth: '1px 1px 1px 1px'
        },
        '& > div:nth-child(even)': {
            backgroundColor: 'rgba(0,0,0,0)'
        },
        '& > div:nth-child(odd)': {
            backgroundColor: 'rgba(0,0,0,0.05)'
        }
    }
})

export default function CompactTable({ title, data = [], noTitle, noCommas, rowStyle, tableStyle }) {
    const t = useTranslation()
    const classes = useClasses(styles)
    return (
        <div className={classes.table} style={tableStyle}>

            {!noTitle &&
                <div className={classes.tableHeader}>
                    <span>{
                        title
                    }</span>
                </div>
            }
            <div className={classes.tableRows}>
                {data.map(row => {
                    if (!row) return
                    return (
                        <div style={rowStyle}>
                            <span style={{ fontWeight: row.boldLabel ? 'bold' : 'normal', ...row.labelStyle }}>{row.label}{noCommas ? '' : ':'}</span>
                            <span style={{ fontWeight: row.boldValue ? 'bold' : 'normal', ...row.valueStyle }}>{row.value || "---"}</span>
                        </div>
                    )
                })}
            </div>

        </div >

    )
}