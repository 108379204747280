import { FormControl, MenuItem, Select } from "@mui/material";
import { useCallback } from "react";
import AppContext, { useAppContext } from "./AppContextCheckout";
import { useTranslation } from "./IntlContext/IntlContext";
import getLocale from '../locale'
import './locale.css'

export function LocaleSelect() {
  const { setAppState, lang, language } = useAppContext(AppContext)

  const t = useTranslation()

  const handleLocaleChange = useCallback(async lang => {
    const { locale } = await getLocale(lang)
    localStorage.setItem("locale", lang)
    setAppState({ language: lang, locale })
  }, [])

  return (
    <div style={{ position: "fixed", right: '5px', top: 0, zIndex: 1101 }}>
      <FormControl variant="outlined">
        <Select
          id="select_locale"
          data-cy="select_locale"
          value={language || lang}
          onChange={e => handleLocaleChange(e.target.value)}
          classes={{ root: 'localeSelect' }}
          className="localeSelect"
        >
          <MenuItem value={'pt-BR'}>
            {t('@@ Português (Brasil)')}
          </MenuItem>
          <MenuItem value={'fr-FR'}>
            {t('@@ Français')}
          </MenuItem>
          <MenuItem value={'es-ES'}>
            {t('@@ Español')}
          </MenuItem>
          <MenuItem value={'en-US'}>
            {t('@@ English')}
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  )
}