import { createBrowserHistory } from "history"
import React, { useEffect } from "react"
import { RouterProvider, createBrowserRouter } from "react-router-dom"
// import 'antd/dist/antd.css'

import AppContext from "./components/AppContextCheckout"
import Loader from "./components/Loader"
import getInitialState from "./core/getInitialState"
import useReduxState from "./core/useReduxState"
// import TestPage from './pages/dev'

import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js"
import { SnackbarProvider } from "notistack"
import { reactPlugin } from "./AppInsights.js"
import CheckoutProduct from "./pages/checkoutProductPage"

const NO_AUTH_ROUTES = [
	"login",
	"reset",
	"forgot",
	"checkout",
	"landing-page",
	"edit-payment",
	"update-payment-mean",
	"change-payment-method",
	"dev",
	"dev-subscriptions",
	"c"
]
const NO_AUTH_RE = new RegExp(`^/(${NO_AUTH_ROUTES.join("|")})`)

export default function App({ key }) {

	const theme = {
		background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
	};


	const [getState, setAppState] = useReduxState(
		AppContext.init({
			loadingAppState: true,
			radio: "credit_card",
			radioInputs: [],
		})
	)


	const history = createBrowserHistory()

	useEffect(() => {
		const url = history.location.pathname
		// setAppState({ loadingAppState: false })

		getInitialState({ url }, NO_AUTH_RE).then((state) => {

			setAppState(state)
		})

	}, [])

	const { loadingAppState } = getState()

	if (loadingAppState) {
		return (
			<div
				className="flex-col center-a center-b"
				style={{
					width: "100vw",
					height: "100vh",
				}}
			>
				<Loader />
			</div>
		)
	}

	const router = createBrowserRouter([
		{
			path: "/",
			element: (
				<div>
				</div>
			),
		},
		{
			path: "c/:id",
			element: <CheckoutProduct />,
		},
		{
			path: "dev",
			element: <CheckoutProduct />,
		},
	]);

	return (
		<AppInsightsErrorBoundary onError={() => <h1>Ocorreu um erro na aplicação. </h1>} appInsights={reactPlugin}>
			<AppContext.Provider value={{ ...getState(), setAppState, history }}>
				<SnackbarProvider
					anchorOrigin={{
						vertical: "top",
						horizontal: "center",
					}}
					maxSnack={3}
					autoHideDuration={3000}
				>
					<RouterProvider router={router} />
				</SnackbarProvider>
			</AppContext.Provider>
		</AppInsightsErrorBoundary >
	)
}
