import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReCaptcha from 'react-google-recaptcha';
import AppContext, { useAppContext } from "../components/AppContextCheckout";
import { useTranslation } from '../components/IntlContext/IntlContext';
import Loader from '../components/Loader';
import { LocaleSelect } from '../components/LocaleSelect';
import useMsgBox from '../components/SweetAlert';
import SuccessBoleto from '../components/checkout-1.5.4/SuccessBoleto';
import SuccessPage from '../components/checkout-1.5.4/SuccessPage';
import SuccessPix from '../components/checkout-1.5.4/SuccessPix';
import ProductOneStep from '../components/forms/productOneStep';
import { OrchService } from '../core/OrchService';
import getLocale from '../locale';
import './checkoutProduct.css';

import checkoutInfo from './checkoutInfo.json';
import { useParams } from 'react-router';
import formSchema from '../core/formSchema';




let _ = require('lodash');
function isBackgroundDark(hexcolor = "#000000") {
    hexcolor = hexcolor.replace("#", "");
    var r = parseInt(hexcolor.substr(0, 2), 16);
    var g = parseInt(hexcolor.substr(2, 2), 16);
    var b = parseInt(hexcolor.substr(4, 2), 16);
    var yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq < 128;
}

const base64ToImageSrc = (base64) => {
    return `data:image/png;base64,${base64}`;
};


export default function CheckoutProductPage({ match }) {

    const { id } = useParams()
    const t = useTranslation()
    const { setAppState, payzenForm, lang, language, history } = useAppContext(AppContext);
    const [isLoading, setIsLoading] = useState(true)
    // const company = checkout.company
    const [quantity, setQuantity] = useState(1)
    const [checkout, setCheckout] = useState({})
    const [success, setSuccess] = useState({})
    const [messageError, setMessageError] = useState('')
    let languageLabel = { BRA: "pt-BR", FRA: "fr" };

    const hash = id
    const refCaptcha = useRef()
    const [couponLoading, setCouponLoading] = useState(false)
    const [coupon, setCoupon] = useState({
        id: null,
        code: null,
        value: "",
        discount_amount: 0,
        total_amount: 0,
        tax_amount: 0
    })

    const [totalAmount, setTotalAmount] = useState(0)

    const [loadingState, setLoadingState] = useState({
        customer: false,
        payment: false
    })
    const [isSubmitting, setIsSubmitting] = useState(false)

    const [customer, setCustomer] = useState({})
    const msg = useMsgBox()

    const handleQuantity = (type) => {
        if (type == 'add') {
            return setQuantity(quantity + 1)
        } else {
            if ((quantity - 1) <= 1) {
                return setQuantity(1)
            }
            setQuantity(quantity - 1)
        }
    }

    const checkField = (field) => {
        if (checkout.template.fields.includes(field)) {
            return true
        }
        return false
    }

    useEffect(() => {
        setTotalAmount(checkout.product?.amount * quantity)
    }, [quantity])

    const fetchCheckout = async () => {
        setIsLoading(true)
        try {
            const response = await OrchService.Checkout.Get({ hash })
            // const response = checkoutInfo
            response.template.documents_list = formSchema[response.company.country_code].documentsList
            setCheckout(response)
            setTotalAmount(response.orderAmount)
            handleLocaleChange(('pt-BR' || languageLabel[response.company.address.country_code]), setAppState);

        } catch (e) {
            switch (e.status) {
                case 403:
                    setMessageError(t.errors["hash.used"]);
                    break;
                case 409:
                    setMessageError(t.errors["hash.disabled"]);
                    break;

                default:
                    setMessageError(t.errors["hash.server_error"]);
                    break;
            }

        }
        return setIsLoading(false)
    }


    const removeCharacters = (str = "") => {
        return str.replace(/\D/g, '');
    };

    const onOneStepSubmit = async (values) => {


        const [MM, YY] = values.cc_expiry_date.split("/")

        const expiry_date = `${MM}/20${YY}`

        const billingInfo = {
            zip: removeCharacters(values.billing_address_zip),
            address_line_1: values.billing_address_street1,
            street_number: values.billing_address_number,
            state: values.billing_address_state,
            district: values.billing_address_district,
            city: values.billing_address_city,
            complement: values.billing_address_complement,
            country_code: values.billing_address_country || checkout.company.country_code
        }
        const shippingInfo = {
            zip: removeCharacters(values.shipping_address_zip),
            address_line_1: values.shipping_address_street1,
            street_number: values.shipping_address_number,
            state: values.shipping_address_state,
            district: values.shipping_address_district,
            city: values.shipping_address_city,
            complement: values.shipping_address_complement,
            country_code: values.shipping_address_country || checkout.company.country_code,
        }

        const customerInfoWithAddress = {
            name: values.first_name + " " + values.last_name,
            email: values.email,
            birth_date: values.birth_date ? moment(values.birth_date).format("YYYY/MM/DD") : null,
            mobile_phone: values.mobile_phone,
            document_type: values.document_type,
            document_number: removeCharacters(values.document_number),
            ...billingInfo
        }

        const customerInfo = {
            name: values.first_name + " " + values.last_name,
            email: values.email,
            birth_date: values.birth_date ? moment(values.birth_date).format("YYYY-MM-DD") : null,
            mobile_phone: values.mobile_phone,
            document_type: values.document_type,
            document_number: removeCharacters(values.document_number),
            phone: values.phone,
            // document: (checkout.template.required_fields.document || ['pix-cobv', 'boleto'].includes(values.type)) ? {
            //     type: values.document_type,
            //     number: values.document_number
            // } : null,
        }
        const paymentInfo = {
            number: values.cc_number,
            holder_name: values.cc_cardholder,
            exp_month: parseInt(expiry_date.split("/")[0]),
            exp_year: parseInt(expiry_date.split("/")[1]),
            cvv: values.cvv,
        }

        const fieldsHasAnyValues = (obj) => Object.values(obj).some(value => value !== null && value !== "");

        const ccForm = {
            payments: [{
                amount: checkout.data.amount,
                payment_method: values.type,
                card: paymentInfo,
            }],
            customer: {
                ...customerInfo,
                ...billingInfo,
                // billing_address: (checkField("billing_address") || ['pix-cobv', 'boleto'].includes(values.type)) ? billingInfo : null,
            },
            customer: customerInfo,
            billing_address: checkField("billing_address") ? { ...billingInfo, country_code: "BRA" } : null,
            shipping_address: (checkField("shipping_address") && fieldsHasAnyValues(shippingInfo)) ? shippingInfo : null
        }

        const pixForm = {
            payments: [{
                amount: checkout.data.amount,
                payment_method: values.type,
            }],
            customer: customerInfo,
            billing_address: { ...billingInfo, country_code: "BRA" },
            shipping_address: (checkField("shipping_address") && fieldsHasAnyValues(shippingInfo)) ? shippingInfo : null
        }

        const boletoForm = {
            payments: [{
                amount: checkout.data.amount,
                payment_method: values.type,
            }
            ],
            customer: customerInfo,
            billing_address: { ...billingInfo, country_code: "BRA" },
            shipping_address: (checkField("shipping_address") && fieldsHasAnyValues(shippingInfo)) ? shippingInfo : null
        }

        const form = {
            payments: [{
                amount: checkout.data.amount,
                payment_method: values.type,
            }],
            customer: {
                ...customerInfo,
                ...billingInfo
                // billing_address: (checkField("billing_address") || ['pix-cobv', 'boleto'].includes(values.type)) ? billingInfo : null,
            },
            shipping_address: checkField("shipping_address") ? shippingInfo : null
        }

        try {
            setLoadingState({ ...loadingState, payment: true })
            let recaptchaToken = null

            if (['production'].includes(process.env.REACT_APP_ENVIRONMENT)) {
                recaptchaToken = await refCaptcha.current.executeAsync()
            }
            const data = await OrchService.Checkout.Pay({
                payment: ['card', 'free'].includes(values.type)
                    ? ccForm
                    : ['pix-cobv'].includes(values.type)
                        ? pixForm
                        : ['boleto'].includes(values.type)
                            ? boletoForm
                            : form,
                session_token: checkout.session_token,
                hash,
                recaptchaToken
            })
            if (data.status === 'refused') {
                setLoadingState({ ...loadingState, payment: false })
                return msg.err({ title: t.errors.label, text: t.errors['payments.transaction_refused'] })
            }

            setLoadingState({ ...loadingState, payment: false })
            return setSuccess({ type: values.type, customer: customerInfo, installment: form.installments, successInfo: data, amount: data.amount })

        } catch (e) {
            setLoadingState({ ...loadingState, payment: false })
            return msg.err({ title: t.errors.label, text: t.errors[e?.response?.data?.parameter] || t.errors['generic.post'] })

        }
    }

    const handleLocaleChange = useCallback(async lang => {
        const { locale } = await getLocale(lang)
        localStorage.setItem("locale", lang)
        setAppState({ language: lang, locale })
    }, [])


    useEffect(() => {
        fetchCheckout()
    }, [])



    if (messageError) return <div className='flex-row center-a center-b' style={{ width: '100%', height: '100vh' }}>
        {messageError}
    </div>

    if (isLoading) return <div className='flex-row center-a center-b' style={{ width: '100%', height: '100vh' }}>
        <Loader />
    </div>


    if (success.type) {
        if (success.type === 'card') return (
            <SuccessPage
                checkoutInfo={checkout}
                isProduct
                userInfo={{ ...success.customer, totalPaid: { amount: totalAmount, currency: checkout.product?.currency, installment: success.installment } }}
                success_info={{ ...success.data, product: checkout.product || undefined }}
            />
        )
        if (success.type === 'boleto') return (
            <SuccessBoleto
                isProduct
                boleto={success.successInfo.transactions[0]}
                checkoutInfo={checkout}
                userInfo={{ ...success.customer, totalPaid: { amount: checkout.data.amount, currency: checkout.product?.currency } }}
            />
        )
        if (success.type === 'pix-cobv') return (
            <SuccessPix
                pixData={success.successInfo.transactions[0]}
                checkoutInfo={checkout}
                isProduct
                userInfo={{ ...success.customer, totalPaid: { amount: checkout.data.amount, currency: checkout.product?.currency } }}
            />
        )
        if (success.type === 'free') return (
            <SuccessPage
                checkoutInfo={checkout}
                isProduct
                userInfo={{ ...success.customer, totalPaid: { amount: success.amount, currency: checkout.product?.currency, installment: success.installment } }}
                primaryColor={checkout.company && checkout.company.style.primary_color}
                success_info={{ ...success.data, product: checkout.product || undefined }}
            />
        )
    }



    return (
        <WrapperContainer
            checkout={checkout}
        >
            <LocaleSelect />
            <div className="flex-row content" style={{ backgroundColor: checkout.template.style.colors.background, padding: 20 }}>
                <div className='flex-col center-b' style={{ width: "100%" }}>
                    <div>
                        <ReCaptcha
                            size='invisible'
                            onChange={() => null}
                            ref={refCaptcha}
                            sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                        />
                        <ProductOneStep checkout={checkout} totalAmount={checkout.data.amount} onSubmit={(props) => onOneStepSubmit(props)} loadingState={loadingState} />
                    </div>
                </div>
            </div>
        </WrapperContainer>
    )
}

const WrapperContainer = ({ children, checkout }) => {
    const style = checkout.template.style



    // const headerLogo = base64ToImageSrc(style.images.logo)
    const headerLogo = style.images.logo
    return (
        <div className='container' style={{ backgroundColor: style.colors.background }}>
            <div className='header' style={{ backgroundColor: style.colors.header }}>

                {/* TODO: REMOVE MOCK IMAGE! */}
                <img src={headerLogo} alt='logo'></img>
            </div>
            <div>
                {children}
            </div>
            {/* <div className='footer' style={{ backgroundColor: style.secundary_color }}>
                    <div>
                        <div>
                            <div style={{ color: "white" }} noPadding>
                                <span style={{ color: isBackgroundDark(company.style.secundary_color) ? "white" : 'black' }} className={classes.label}>{`${company.legal_name}`}</span>
                            </div>
                            {company.document_type && company.document_number &&
                                <span style={{ color: isBackgroundDark(company.style.secundary_color) ? "white" : 'black' }}>
                                    {`${company.document_type.toUpperCase()} ${company.document_number}`}
                                </span>
                            }
                            {company.address?.street &&
                                <div noPadding>
                                    <span style={{ color: isBackgroundDark(company.style.secundary_color) ? "white" : 'black' }}>
                                        {`${company.address.street} ${company.address.number ? (company.address.number) : ""}`}&nbsp;
                                    </span>
                                </div>
                            }
                            {company.address?.district &&
                                <div noPadding>
                                    <span style={{ color: isBackgroundDark(company.style.secundary_color) ? "white" : 'black' }} noPadding>
                                        {company.address.district}
                                    </span>
                                </div>
                            }
                            {company.address?.city && company.address?.zip &&
                                <div noPadding>
                                    <span style={{ color: isBackgroundDark(company.style.secundary_color) ? "white" : 'black' }} className={classes.label}>
                                        <span
                                        >{`${company.address.city} - ${company.address.zip}`}</span>
                                    </span>
                                </div>
                            }
                            {company.address?.second_street && (
                                <div noPadding>
                                    <span style={{ color: isBackgroundDark(company.style.secundary_color) ? "white" : 'black' }}>
                                        {company.address.second_street}
                                    </span>
                                </div>
                            )}
                            {company.email &&
                                <div noPadding>
                                    <span style={{ color: isBackgroundDark(company.style.secundary_color) ? "white" : 'black' }}>
                                        {company.email}
                                    </span>
                                </div>
                            }
                            {company.phone && company.phone.length > 5 &&
                                <div noPadding>
                                    <span style={{ color: isBackgroundDark(company.style.secundary_color) ? "white" : 'black' }}>
                                        {company.phone}
                                    </span>
                                </div>
                            }
                            {company.site &&
                                <div noPadding>
                                    <span style={{ color: isBackgroundDark(company.style.secundary_color) ? "white" : 'black' }}>
                                        {company.site}
                                    </span>
                                </div>
                            }
                        </div>

                        <div className='flex-col'>
                            <span className="flex-row">
                                <span className={classes.logosFooter}>
                                    <img alt="pci-logo" src={pciLogo} width="40px" />
                                </span>
                                <span className={classes.logosFooter}>
                                    <img
                                        onClick={() => {
                                            window.location = "https://cyclopay.com"
                                        }}
                                        alt="ciclopay-logo"
                                        src={isBackgroundDark(company.style.secundary_color) ? cyclopayWhite : cycloPay}
                                        width="70px"
                                    />
                                </span>
                            </span>
                        </div>
                    </div>
                </div> */}
            <div className='footer' style={{ backgroundColor: style.colors.footer }}>
                <div>

                    <img src="https://backoffice-cyclopay-dev.veripag.com.br/images/cyclopay-blue-logo2.svg" alt="cyclopay.svg" style={{ height: 50 }} />
                    <img src="https://cyclopay-merchant-dev.veripag.com.br/static/media/pci-logo.604dc226.png" alt="cyclopay.svg" style={{ height: 50 }} />
                </div>
            </div>
        </div>
    )
}