import { Icon } from '@iconify/react';
import PaymentIcon from '@mui/icons-material/Payment';
import React, { useEffect, useState } from "react";
import { AiOutlineBarcode } from 'react-icons/ai';
import { useTranslation } from "../IntlContext/IntlContext";
import RenderInputsV4 from "../checkout-1.5.4/forms/RenderInputsV4";
import "./styles.css";

const PaymentMethodsList = ({
    availableMethods = ['card', 'boleto', 'pix-cobv'],
    onSelect = () => null,
    selectedMethod = 'card',
    formikprops = {},
    checkout = {},
    totalAmount = 0,
    customer = {},
    couponId = null,
    setSuccessPage = () => null,
    isSubmitting = false,
}) => {
    const t = useTranslation()

    const [isAlerting, setIsAlerting] = useState(false)

    const blinkAlert = () => {
        setIsAlerting(true)
        setTimeout(() => {
            setIsAlerting(false)
        }, 1000)
    }

    const RenderIcon = ({ method }) => {
        switch (method) {
            case 'card':
                return <PaymentIcon style={{ fontSize: 30 }} />
            case 'boleto':
                return <AiOutlineBarcode style={{ fontSize: 30 }} />
            case 'pix-cobv':
                return <Icon icon="ic:baseline-pix" style={{ fontSize: '30px' }} />

            default:
                return null
        }
    }

    useEffect(() => {
        if (isSubmitting) {
            blinkAlert()
        }
    }
        , [isSubmitting])


    return (
        <>
            <div className="flex-row" style={{ flexWrap: 'wrap' }}>

                {availableMethods.includes('card') && <div role='button' className="flex-row center-b" onClick={() => onSelect('card')} style={{ flex: 1, margin: 10, border: '1px solid #e5e5e5', padding: 10, borderRadius: 10, cursor: "pointer", backgroundColor: selectedMethod === 'card' ? "#e6e6e6" : "", }}>
                    <RenderIcon method={'card'} />
                    <span style={{ marginLeft: 5 }}>
                        {t.titles[`payment_method.${'credit_card'}`]}
                    </span>
                </div>}

                {availableMethods.includes('pix-cobv') && <div role='button' className="flex-row center-b" onClick={() => onSelect('pix-cobv')} style={{ flex: 1, margin: 10, border: '1px solid #e5e5e5', padding: 10, borderRadius: 10, cursor: "pointer", backgroundColor: selectedMethod === 'pix-cobv' ? "#e6e6e6" : "", }}>
                    <RenderIcon method={'pix-cobv'} />
                    <span style={{ marginLeft: 5 }}>
                        {t.titles[`payment_method.${'pix'}`]}
                    </span>
                </div>
                }
                {availableMethods.includes('boleto') && <div role='button' className="flex-row center-b" onClick={() => onSelect('boleto')} style={{ flex: 1, margin: 10, border: '1px solid #e5e5e5', padding: 10, borderRadius: 10, cursor: "pointer", backgroundColor: selectedMethod === 'boleto' ? "#e6e6e6" : "", }}>
                    <RenderIcon method={'boleto'} />
                    <span style={{ marginLeft: 5 }}>
                        {t.titles[`payment_method.${'boleto'}`]}
                    </span>
                </div>}
            </div>
            {selectedMethod === "card" &&
                <>
                    <RenderInputsV4
                        formikprops={formikprops}
                        checkoutConfig={checkout}
                        rows={[
                            ['cc_number'],
                            ['cc_expiry_date', 'cvv'],
                            ['cc_cardholder'],
                        ]} />
                </>}

            {(['pix-cobv', 'boleto'].includes(selectedMethod)) &&
                <>
                    {formikprops.values.billing_address_country === 'BRA' ?
                        <>
                            {!checkout.template.fields?.includes('document') && (
                                <>
                                    <span style={{ marginLeft: 10 }}>
                                        {t.forms.document_type}:
                                    </span>
                                    <RenderInputsV4
                                        // disabledInputs={disabledInputsBoleto()}
                                        formikprops={formikprops}
                                        checkoutConfig={checkout}
                                        rows={[
                                            ['document_type', 'document_number']
                                        ]} />
                                </>
                            )}

                            {!checkout.template.fields?.includes('billing_address') && (
                                <>
                                    <span style={{ marginLeft: 10 }}>
                                        {t.titles['billing_address.form']}:
                                    </span>
                                    <RenderInputsV4
                                        formikprops={formikprops}
                                        checkoutConfig={checkout}
                                        rows={[
                                            ['billing_address_zip'],
                                            ['billing_address_street1'],
                                            ['billing_address_number', 'billing_address_district'],
                                            ['billing_address_city', 'billing_address_state_br'],
                                            ['billing_address_complement']
                                        ]} />

                                </>

                            )}
                        </>
                        :
                        <>
                            <span className={isAlerting ? "div-blink" : ""} style={{ marginLeft: 10 }}>
                                {t("@@ Este meio de pagamento não está disponível para o seu país de cobrança.")}
                            </span>
                        </>
                    }
                </>}
        </>
    )

}

export default PaymentMethodsList