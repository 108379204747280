import FileCopyIcon from "@mui/icons-material/FileCopy"
import GetAppIcon from "@mui/icons-material/GetApp"
import { Button } from "@mui/material"
import { saveAs } from "file-saver"
import { useSnackbar } from "notistack"
import React, { useEffect } from "react"
import { Icon } from '@iconify/react';
import { barcode } from "react-icons-kit/fa"
import toCurrency from "../../../core/toCurrency"
import SectionMain from "../../../layout/SectionMain"
import useLocalDateFunction from "../../../utils/useLocalDateFunction"
import { useTranslation } from "../../IntlContext/IntlContext"
import CompactTable from "../../TableRenderer"
import Title from "../forms/Title"
import { styled } from "@mui/system"

const DivRoot = styled("div")({
	// height: "100vh",
	justifyContent: "center",
	alignItems: "center",
});

const DivLogo = styled("div")({
	backgroundImage: ({ logo }) => `url(${logo})`,
	backgroundSize: "contain",
	backgroundPosition: "center",
	backgroundRepeat: "no-repeat",
	width: "100%",
	height: "10vw",
	maxHeight: 100,
	display: "flex",
	justifyContent: "center",
});

const SectionMainIcon = styled(SectionMain)({
	borderRadius: "100%",
	height: 120,
	width: 120,
	paddingTop: 10,
	color: "#fff",
	background: ({ color }) => color,
	alignItems: "center",
	justifyContent: "center",
});

const InputCode = styled("input")({
	border: "1px solid rgba(0,0,0,0.19)",
	borderRadius: "15px",
	padding: "5px 10px",
	textAlign: "center",
	width: "100%",
	fontSize: "16px",
});

const StyledButton = styled(Button)({
	border: "1px solid rgba(0,0,0,0.19)",
	borderRadius: "15px",
	textTransform: "none",
	color: ({ color }) => color,
});

const SuccessBoleto = (props) => {

	const {
		primaryColor,
		logo,
		boleto = { link: "", payment_number: "" },
		userInfo,
		checkoutInfo,
		isProduct
	} = props


	const t = useTranslation()
	const renderDate = useLocalDateFunction('DATE')
	const { enqueueSnackbar } = useSnackbar()

	const copyCheckoutLinkToClipboard = () => {
		try {
			document.getElementById("boleto-code").select()
			document.execCommand("copy")

			enqueueSnackbar(t.checkout.code_copied, {
				options: {
					autoHideDuration: 1000,
				},
				variant: "success",
			})
		} catch (e) {
			console.log({ e })
		}
	}

	const getBoleto = async () => {
		const qs = new URLSearchParams(window.location.search)
		const hash = qs.get("hash")
		try {
			await saveAs(
				`${process.env.REACT_APP_BILL_SERVICE_URL}/checkout/boleto/print?hash=${hash}&protocolo=${boleto.protocolo}`
			)
			//
		} catch (e) {
			console.log({ e })
		}
	}

	const renderFrequency = (pricing_option) => {
		if (pricing_option?.interval < 2) {
			return t.frequency[pricing_option?.frequency.toUpperCase()]
		} else {
			return t.generics.each + " " + pricing_option.interval + " " + t.frequency_quantity[pricing_option?.frequency.toUpperCase()]
		}
	}

	const renderTrialAmount = (success_info) => {
		if (success_info.amount === 0) {
			if (checkoutInfo?.plan?.trial.interval > 1) {
				return t("^titles.gratuitous", checkoutInfo?.plan?.trial.interval + " " + t.frequency_quantity[checkoutInfo?.plan?.trial.interval_unit])
			} else {
				return t("^titles.gratuitous", (checkoutInfo?.plan?.trial.interval + " " + t.frequency_interval_unit[checkoutInfo?.plan?.trial.interval_unit]))
			}
		} else {
			return toCurrency(success_info.amount) + " " + t("^titles.trial", (checkoutInfo?.plan?.trial.interval + " " + t.frequency_interval_unit[checkoutInfo?.plan?.trial.interval_unit]))

		}
	}

	const tableData = [
		{
			label: `Número do pedido:`,
			value: checkoutInfo.data.order_id,
			boldValue: true,
			boldLabel: true,
		},
		{
			label: t.checkout.total_amount,
			value: toCurrency(boleto.amount, "BRL"),
			boldValue: true,
			boldLabel: true,
		},
		(userInfo.name || userInfo.first_name)
			? {
				label: `${t.titles.customer}:`,
				value: userInfo.name || userInfo.first_name + " " + userInfo.last_name,
				boldValue: true,
				boldLabel: true
			}
			: null,
		(userInfo.email)
			? {
				label: `${t.checkout.email}:`,
				value: userInfo.email,
				boldValue: true,
				boldLabel: true
			}
			: null,
	]


	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<DivRoot>
			<div
				style={{
					width: "100%",
					backgroundColor: primaryColor,
					padding: 5,
					marginBottom: 40,
				}}
			>
				<DivLogo >
										<img src={checkoutInfo.template.style.images.logo} style={{ height: '100%' }} />

				</DivLogo>
			</div>

			<div className="flex-row center-a">
				<div
					className="flex-col center-b"
					style={{
						borderRadius: 25,
						border: "1px solid rgba(0,0,0,0.19)",
						width: "80vw",
						padding: "30px 15px",
						maxWidth: "1440px",
						margin: 40,
						marginTop: 0,
					}}
				>
					<div className="flex-col center-b" style={{ width: "fit-content" }}>
						< div style={{ display: 'flex', justifyContent: 'center' }}>
							<SectionMainIcon noPadding>
								<Icon
									icon="mdi:success-circle"
									color="#09b64e"
									width="100"
									height="100"
								/>
							</SectionMainIcon>
						</div>
						<SectionMain position="center" direction="column" alignItems="center">
							<Title align="center">
								<span style={{ fontSize: 18 }}>
									{boleto.amount > 0
										? isProduct
											? t.products.pay_boleto
											: t.messages["success_submit_boleto.subscription"]
										: "Pagamento concluído com sucesso!"}
								</span>
							</Title>
						</SectionMain>
						<br />
						<div style={{ paddingBottom: 15 }}>
							<Title align="center">
								<h3 style={{ margin: 0 }}>{t("^currencies.amount")}:</h3>
								<h2 style={{ margin: 0 }}>{toCurrency(boleto.amount, "BRL")}</h2>
							</Title>
						</div>
					</div>
					{boleto.amount > 0 && (
						<div className="flex-col center-b" style={{ width: "80%" }}>
							<SectionMain position="center" direction="column" alignItems="center">
								<InputCode
									value={boleto.payment_number}
									id="boleto-code"
								></InputCode>
							</SectionMain>
							<div style={{ marginBottom: "15px", marginTop: 15 }}>
								<StyledButton
									onClick={copyCheckoutLinkToClipboard}
									startIcon={<FileCopyIcon htmlColor={primaryColor} />}
									variant="text"
									color="primary"
								>
									{t.checkout.copy_code}
								</StyledButton>
							</div>
							<div style={{ marginBottom: "15px" }}>
								<a
									target="_blank"
									href={boleto.extra}
									style={{ textDecoration: "none" }}
								>
									<StyledButton
										color="primary"
										variant="text"
										startIcon={<GetAppIcon htmlColor={primaryColor} />}
									>
										{t.checkout.download_boleto}
									</StyledButton>
								</a>
							</div>
							<br />
						</div>
					)}
					{checkoutInfo.plan?.trial.interval > 0 && (
						<>
							<h3 style={{ margin: 0 }}>
								{t.generics.from_day}{" "}
								<b>{renderDate(checkoutInfo.plan.trial.next_charge)}</b>,{" "}
								{t(
									"^messages.trial_interval.charge",
									toCurrency(checkoutInfo.plan.amount, checkoutInfo.plan.currency),
									t.frequency_interval_unit[checkoutInfo.plan.recurrence.frequency]
								)}
							</h3>
							<br />
						</>
					)}

					<div className="flex-col center-b" style={{ width: "fit-content" }}>
						<CompactTable
							data={tableData}
							noCommas
							noTitle
							tableStyle={{ width: "100%", margin: "15px 0 0 0" }}
							rowStyle={{ color: "rgba(0, 0, 0, 0.8)" }}
						/>

						<SectionMain position="center" direction="column" alignItems="center">
							<span style={{ fontSize: 18, maxWidth: 300, textAlign: "center" }}>
								{t("^messages.send_invoice.subscription", "userInfo.email")}
							</span>
						</SectionMain>

					</div>
				</div>
			</div>
		</DivRoot>
	)
}

export default SuccessBoleto
