/**
*
* @param {import("./formSchema.d.ts")} formSchema
*/
const formSchema = {
    "BRA": {
        "language": "pt-BR",
        "documentsList": [
            "CPF",
            "CNPJ"
        ],
        "b2bDocument": "CNPJ",
        "billingAddressList": [
            ["billing_address_zip"],
            ["billing_address_street1"],
            ["billing_address_number", "billing_address_district"],
            ["billing_address_city", "billing_address_state_br"],
            ["billing_address_complement"]
        ],
        "shippingAddressList": [
            ["shipping_address_zip"],
            ["shipping_address_street1"],
            ["shipping_address_number", "shipping_address_district"],
            ["shipping_address_city", "shipping_address_state_br"],
            ["shipping_address_complement"]
        ],
        "countryDial": 55,
        "defaultCountryOption": { value: "BRA", label: "Brasil" },
        "phoneMask": "+99 (99) 999999999"

    },
    "PER": {
        "language": "es-ES",
        "documentsList": [
            "DNI",
            "CE",
            "RUC",
        ],
        "b2bDocument": "RUC",
        "billingAddressList": [
            [
                "billing_address_street1"
            ],
            [
                "billing_address_city",
                "billing_address_state"
            ],
            ["billing_address_zip_no_mask"],
        ],
        "shippingAddressList": [
            [
                "shipping_address_street1"
            ],
            [
                "shipping_address_city",
                "shipping_address_state"
            ],
            [
                "shipping_address_zip_no_mask"
            ],
        ],
        "countryDial": 51,
        "defaultCountryOption": { value: "PER", label: "Peru" },
        "phoneMask": "+99 (999) 999-999"

    },
    "CHL": {
        "language": "es-ES",
        "documentsList": [
            "CI",
            "RUT",
        ],
        "b2bDocument": "RUT",
        "billingAddressList": [
            [
                "billing_address_street1"
            ],
            [
                "billing_address_city",
                "billing_address_state"
            ],
            [
                "billing_address_zip_no_mask"
            ],
        ],
        "shippingAddressList": [
            [
                "shipping_address_street1"
            ],
            [
                "shipping_address_city",
                "shipping_address_state"
            ],
            [
                "shipping_address_zip_no_mask"
            ],
        ],
        "countryDial": 56,
        "defaultCountryOption": { value: "CHL", label: "Chile" },
        "phoneMask": "+99-9-9999-9999"


    },
    "ARG": {
        "language": "es-ES",
        "documentsList": [
            "DNI",
            "CUIT"
        ],
        "b2bDocument": "CUIT",
        "billingAddressList": [
            [
                "billing_address_street1"
            ],
            [
                "billing_address_city",
                "billing_address_state"
            ],
            [
                "billing_address_zip_no_mask"
            ],
        ],
        "shippingAddressList": [
            [
                "shipping_address_street1"
            ],
            [
                "shipping_address_city",
                "shipping_address_state"
            ],
            [
                "shipping_address_zip_no_mask"
            ],
        ],
        "countryDial": 54,
        "defaultCountryOption": { value: "ARG", label: "Argentina" },
        "phoneMask": "+99 (999) 999-9999"


    },
    "COL": {
        "language": "es-ES",
        "documentsList": [
            "TI",
            "CI",
            "NIT"
        ],
        "b2bDocument": "NIT",
        "billingAddressList": [
            [
                "billing_address_street1"
            ],
            [
                "billing_address_city",
                "billing_address_state"
            ],
            [
                "billing_address_zip_no_mask"
            ],
        ],
        "shippingAddressList": [
            [
                "shipping_address_street1"
            ],
            [
                "shipping_address_city",
                "shipping_address_state"
            ],
            [
                "shipping_address_zip_no_mask"
            ],
        ],
        "countryDial": 57,
        "defaultCountryOption": { value: "COL", label: "Colombia" },
        "phoneMask": "+99 (999) 999-9999"
    },
    "FRA": {
        "language": "fr-FR",
        "documentsList": [
            "CNI",
            "SIRET",
        ],
        "b2bDocument": "SIRET",
        "billingAddressList": [
            ["billing_address_street1"],
            ["billing_address_street2"],
        ],
        "shippingAddressList": [

            [
                "shipping_address_street1"
            ],
            [
                "shipping_address_street2"
            ],

        ],
        "countryDial": 33,
        "defaultCountryOption": { value: "FRA", label: "France" },
        "phoneMask": "+99 (999) 999-999"

    },
    "USA": {
        //Complete Fields
        // "language":"",
        //"documentsList": [
            //     "CNI",
            //     "SIRET",
            // ],
            // "b2bDocument": "SIRET",
            // "billingAddressList": [
            //     ["billing_address_street1"],
            //     ["billing_address_street2"],
            // ],
            // "shippingAddressList": [

            //     [
            //         "shipping_address_street1"
            //     ],
            //     [
            //         "shipping_address_street2"
            //     ],

            // ],
            // "countryDial": 33,
            // "defaultCountryOption": { value: "FRA", label: "France" },
            "phoneMask": "+9 (999) 999-9999"
    }
}

export default formSchema
