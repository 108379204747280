import React from "react"
import { Grid, makeStyles } from "@mui/material"
import { backgroundColor } from "../color"
import styled from "@mui/system/styled"

const GridRoot = styled(Grid)({
	padding: (theme) =>
		theme.noPadding ? 0 : theme.miniPadding ? "10px 0" : "15px 0",
	justifyContent: (theme) => theme.position,
	backgroundColor: ({ debug }) => debug && debug,
	alignItems: ({ alignItems }) => alignItems && alignItems,
});

const SectionMain = ({
	children,
	position,
	noPadding,
	miniPadding,
	debug,
	alignItems,
	...props
}) => {

	return (
		<GridRoot container {...props}>
			{children}
		</GridRoot>
	)
}

export default SectionMain
