import { Formik } from "formik"
import React, { useEffect, useState } from "react"

import { Button, ThemeProvider, createTheme } from '@mui/material'
import toCurrency from "../../core/toCurrency"
import { useTranslation } from "../IntlContext/IntlContext"
import Loader from "../Loader"
import PaymentMethodsList from "../PaymentMethods"
import RenderInputsV4 from "../checkout-1.5.4/forms/RenderInputsV4"
import { FormikValidationsCustom } from "../checkout-1.5.4/forms/RenderInputsV4/FormikValidations"
import formSchema from "../../core/formSchema"
import moment from "moment"




const ProductOneStep = ({ checkout, onSubmit = () => null, totalAmount = 0, loadingState = {} }) => {
    const t = useTranslation()
    const [value, setValue] = React.useState(0);


    const theme = createTheme({
        palette: {
            primary: { main: checkout.template.style.colors.button },
        },
    });

    const [valErrors, setValErrors] = useState({})
    const [isSubmitting, setIsSubmitting] = useState(false)

    const [TCAgreed, setTCAgreed] = useState(false)


    const [selectedMethod, setSelectedMethod] = useState(checkout.template.payment_methods.includes('card') ? 'card' : checkout.template.payment_methods[0])

    const checkField = (field) => {
        return checkout.template.fields.includes(field)
    }

    const formatRow = (...fields) => {
        const row = []
        fields.map(field => {
            if (checkField(field)) row.push(field)
        })
        return row
    }



    const disabledInputsCustomer = () => {
        if (!checkout.data.customer) return {}
        let initialValues = {
            first_name: Boolean(checkout.data.customer.name),
            last_name: Boolean(checkout.data.customer.name),
            mobile_phone: Boolean(checkout.data.customer.mobile_number),
            email: Boolean(checkout.data.customer.email),
            birth_date: Boolean(checkout.data.customer.birth_date),
            country_code: Boolean(checkout.data.customer.country_code),
            document_type: Boolean(checkout.data.customer.document_type),
            document_number: Boolean(checkout.data.customer.document_number),
            billing_address_zip: Boolean(checkout.data.billing_address?.zip),
            billing_address_street1: Boolean(checkout.data.billing_address?.address_line_1),
            billing_address_number: Boolean(checkout.data.billing_address?.street_number),
            billing_address_state: Boolean(checkout.data.billing_address?.state),
            billing_address_district: Boolean(checkout.data.billing_address?.district),
            billing_address_city: Boolean(checkout.data.billing_address?.city),
            billing_address_country: Boolean(checkout.data.billing_address?.country),
            billing_address_complement: Boolean(checkout.data.billing_address?.complement),
            shipping_address_zip: Boolean(checkout.data.shipping_address?.zip),
            shipping_address_street1: Boolean(checkout.data.shipping_address?.address_line_1),
            shipping_address_number: Boolean(checkout.data.shipping_address?.street_number),
            shipping_address_state: Boolean(checkout.data.shipping_address?.state),
            shipping_address_district: Boolean(checkout.data.shipping_address?.district),
            shipping_address_city: Boolean(checkout.data.shipping_address?.city),
            shipping_address_country: Boolean(checkout.data.shipping_address?.country),
            shipping_address_complement: Boolean(checkout.data.shipping_address?.complement),
        }
        return initialValues
    }

    const customerInitialValues = () => {
        if (checkout.data.customer) {
            let initialValues = {
                first_name: checkout.data.customer.name.slice(0, checkout.data.customer.name.indexOf(' ')),
                last_name: checkout.data.customer.name.slice(checkout.data.customer.name.indexOf(' ') + 1),
                phone: checkout.data.customer.phone || "55",
                mobile_phone: checkout.data.customer.mobile_phone || "55",
                email: checkout.data.customer.email,
                birth_date: checkout.data.customer.birth_date ? moment(checkout.data.customer.birth_date) : null,
                country_code: checkout.data.customer.country_code,
                document_type: checkout.data.customer.document_type?.toUpperCase() || formSchema[checkout.company.country_code].documentsList[0] || "CPF",
                document_number: checkout.data.customer.document_number,
                billing_address_zip: checkout.data.billing_address?.zip,
                billing_address_street1: checkout.data.billing_address?.address_line_1,
                billing_address_number: checkout.data.billing_address?.street_number,
                billing_address_state: checkout.data.billing_address?.state,
                billing_address_district: checkout.data.billing_address?.district,
                billing_address_city: checkout.data.billing_address?.city,
                billing_address_country: checkout.data.billing_address?.country || checkout.company.country_code || 'BRA',
                billing_address_complement: checkout.data.billing_address?.complement,
                shipping_address_zip: checkout.data.shipping_address?.zip,
                shipping_address_street1: checkout.data.shipping_address?.address_line_1,
                shipping_address_number: checkout.data.shipping_address?.street_number,
                shipping_address_state: checkout.data.shipping_address?.state,
                shipping_address_district: checkout.data.shipping_address?.district,
                shipping_address_city: checkout.data.shipping_address?.city,
                shipping_address_country: checkout.data.shipping_address?.country || checkout.company.country_code || 'BRA',
                shipping_address_complement: checkout.data.shipping_address?.complement,
            }
            return initialValues
        }
        let initialValues = {
            first_name: "",
            last_name: "",
            phone: "",
            mobile_phone: "",
            email: "",
            country_code: checkout.company.country_code,
            birth_date: null,
            document_type: formSchema[checkout.company.country_code].documentsList[0],
            // document_type: checkout.checkout_config.prioritize_b2b ? "CNPJ" : "CPF",
            document_number: "",
            billing_address_zip: "",
            billing_address_street1: "",
            billing_address_number: "",
            billing_address_state: "",
            billing_address_district: "",
            billing_address_city: "",
            billing_address_country: checkout.company.country_code || 'BRA',
            billing_address_complement: "",
            shipping_address_zip: "",
            shipping_address_street1: "",
            shipping_address_number: "",
            shipping_address_state: "",
            shipping_address_district: "",
            shipping_address_city: "",
            shipping_address_country: checkout.company.country_code || 'BRA',
            shipping_address_complement: "",
        }
        return initialValues
    }

    const CCInitialValues = () => {
        let initialValues = {
            cc_number: "",
            cc_cardholder: "",
            cc_expiry_date: "",
            cvv: "",
            brand: "",
            type: "",
            installments: null

        }
        return initialValues
    }

    // const customValidationConfig = {
    //     customer: true,
    //     billing_address: checkout.checkout_config.billing_address_enabled || (totalAmount > 0 && ['pix', 'boleto'].includes(selectedMethod)),
    //     document: checkout.checkout_config.document_enabled || (totalAmount > 0 && ['pix', 'boleto'].includes(selectedMethod)),
    //     shipping_address: checkout.checkout_config.shipping_address_enabled,
    //     cc_payment: totalAmount > 0 && selectedMethod === 'credit_card'
    // }

    const goToError = (input) => {
        const el = document.getElementsByName(input)[0];
        (el?.parentElement ?? el)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }



    const handleValidation = (values) => {

        const errors = FormikValidationsCustom(
            values,
            checkout,
            t,
            {
                ...checkout.template.required_fields,
                cc_payment: selectedMethod === "card",
                billing_address: checkout.template.required_fields.billing_address || (['pix-cobv', 'boleto'].includes(selectedMethod)),
                document: checkout.template.required_fields.document || (['pix-cobv', 'boleto'].includes(selectedMethod)),
            }, true
        )

        if (['pix-cobv', 'boleto'].includes(selectedMethod) && values.billing_address_country !== 'BRA') {
            errors.billing_address_country = "País inválido"
        }
        setValErrors(errors)
        return errors
    }

    useEffect(() => {
        if (!isSubmitting) return
        if (Object.keys(valErrors).length > 0) {
            goToError(Object.keys(valErrors)[0])
        }
        setIsSubmitting(false)
    }, [valErrors])


    return (
        <ThemeProvider theme={theme}>
            <Formik
                initialValues={{ ...customerInitialValues(), ...CCInitialValues() }}
                validate={(values) => handleValidation(values)}
                onSubmit={props => onSubmit({ ...props, type: selectedMethod })}
            >
                {({
                    handleSubmit,
                    ...formikprops
                }) => (
                    <form onSubmit={(form) => handleSubmit(form)} style={{ width: '100%', height: '100%' }} action="javascript:void(0)">
                        <div className='form-container'>
                            <div className="flex-col" style={{ justifyContent: "space-between", padding: 10 }}>
                                <div className="flex-row" style={{ justifyContent: "space-between", }}>
                                    <h2>
                                        <span style={{ opacity: 0.7 }}>
                                            {t("@@ Número do pedido")}:&nbsp;
                                        </span>
                                        {checkout.data.order_id}
                                    </h2>
                                    <h2>
                                        <span style={{ opacity: 0.7 }}>
                                            {t("@@ Total")}:&nbsp;
                                        </span>
                                        {toCurrency(totalAmount)}
                                    </h2>
                                </div>
                                <div style={{ fontSize: '.8rem', color: "#343434", backgroundColor: "#f4f4f4", borderRadius: 4, padding: 10, overflow: "hidden" }}>
                                    {checkout.data.order_info || "Sem descrição"}
                                </div>
                            </div>

                            <span style={{ padding: 10 }}>
                                {t("@@ Dados do pagador")}:
                            </span>
                            <RenderInputsV4 formikprops={formikprops} checkoutConfig={checkout} disabledInputs={disabledInputsCustomer()} rows={[
                                (formikprops.values["document_type"] !== "CNPJ" ? ['first_name', 'last_name'] : ['first_name']),
                                (formatRow('email', 'birth_date')),
                                (formatRow('country_code')),
                                (checkField('document') ? ['document_type', 'document_number'] : []),
                                (formatRow('phone', 'mobile_phone'))
                            ]} />
                            {checkField("billing_address") &&
                                <>
                                    <span style={{ padding: 10 }}>
                                        {t.titles["billing_address.form"]}:
                                    </span>
                                    <RenderInputsV4 formikprops={formikprops} disabledInputs={disabledInputsCustomer()} checkoutConfig={checkout} rows={[
                                        // ['billing_address_country'],
                                        ...formSchema[formikprops.values.billing_address_country].billingAddressList
                                    ]} />
                                </>
                            }

                            {checkField("shipping_address") &&
                                <>
                                    <span style={{ padding: 10 }}>
                                        {t.titles["shipping_address.form"]}:
                                    </span>
                                    <RenderInputsV4 formikprops={formikprops} disabledInputs={disabledInputsCustomer()} checkoutConfig={checkout} rows={[
                                        // ['shipping_address_country'],
                                        ...formSchema[formikprops.values.shipping_address_country].shippingAddressList
                                    ]} />
                                </>
                            }

                            {totalAmount > 0 &&
                                <>
                                    <span style={{ padding: 10 }}>
                                        {t.generics.payment}:
                                    </span>
                                    <PaymentMethodsList
                                        selectedMethod={selectedMethod}
                                        onSelect={(method) => setSelectedMethod(method)}
                                        availableMethods={checkout.template.payment_methods}
                                        formikprops={formikprops}
                                        totalAmount={totalAmount}
                                        checkout={checkout}
                                        isSubmitting={isSubmitting}
                                    />
                                </>
                            }

                            <div className='flex-row' style={{ width: 'auto', padding: 10 }}>
                                <Button
                                    style={{
                                        width: '100%',
                                        fontSize: '1rem'
                                    }}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    type='submit'
                                    onClick={() => setIsSubmitting(true)}
                                    disabled={loadingState.payment}
                                >
                                    {loadingState.payment &&
                                        <span style={{ marginRight: "10px" }}>
                                            <Loader white size={30} />
                                        </span>
                                    }
                                    {t.buttons['checkout.paid']} {toCurrency(totalAmount)}
                                </Button>
                            </div>
                        </div>
                        {/* <TestForm checkoutConfig={checkout} value={value} formikprops={formikprops} formStep={'one_step'} /> */}

                    </form>
                )}
            </Formik>
        </ThemeProvider>
    )
}

export default ProductOneStep