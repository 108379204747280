import { Icon } from '@iconify/react';
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { Button } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import toCurrency from "../../../core/toCurrency";
import SectionMain from "../../../layout/SectionMain";
import { useTranslation } from "../../IntlContext/IntlContext";
import CompactTable from "../../TableRenderer";
import Title from "../forms/Title";
import { height, styled } from "@mui/system";

const DivRoot = styled("div")({
	// height: "100vh",
	justifyContent: "center",
	alignItems: "center",
});

const DivLogo = styled("div")({
	backgroundSize: "contain",
	backgroundPosition: "center",
	backgroundRepeat: "no-repeat",
	width: "100%",
	height: "10vw",
	maxHeight: 100,
	display: "flex",
	justifyContent: "center",
});

const InputCode = styled("input")({
	border: "1px solid rgba(0,0,0,0.19)",
	borderRadius: "15px",
	padding: "5px 10px",
	textAlign: "center",
	width: "100%",
	fontSize: "16px",
});

const StyledButton = styled(Button)({
	border: "1px solid rgba(0,0,0,0.19)",
	borderRadius: "15px",
	textTransform: "none",

	color: ({ color }) => color,
});

const SuccessPix = ({
	primaryColor,
	logo,
	pixData,
	checkoutInfo,
	userInfo = {},
	isProduct = false
}) => {

	const t = useTranslation()
	const { enqueueSnackbar } = useSnackbar()

	const copyCheckoutLinkToClipboard = () => {
		try {
			document.getElementById("pix-code").select()
			document.execCommand("copy")

			enqueueSnackbar("Código copiado com sucesso!", {
				options: {
					autoHideDuration: 1000,
				},
				variant: "success",
			})
		} catch (e) {
			console.log({ e })
		}
	}

	const Row = ({ label, value }) => (
		<div
			className="flex-row"
			style={{
				width: '100%',
				padding: '8px 0',
				justifyContent: 'space-between'
			}}
		>
			<div className="flex-col center-b">{label}</div>
			<div className="flex-col center-b" style={{ textAlign: 'right' }}>
				{value}
			</div>
		</div>
	)


	const QRCode = ({ data }) => <img src={`data:image/png;base64,${data}`} style={{ maxWidth: '100%' }} />

	// return console.log(pixData)



	const renderFrequency = (pricing_option) => {
		if (pricing_option?.interval < 2) {
			return t.frequency[pricing_option?.frequency.toUpperCase()]
		} else {
			return t.generics.each + " " + pricing_option.interval + " " + t.frequency_quantity[pricing_option?.frequency.toUpperCase()]
		}
	}

	const renderTrialAmount = (success_info) => {
		if (success_info.amount === 0) {
			if (checkoutInfo?.plan?.trial.interval > 1) {
				return t("^titles.gratuitous", checkoutInfo?.plan?.trial.interval + " " + t.frequency_quantity[checkoutInfo?.plan?.trial.interval_unit])
			} else {
				return t("^titles.gratuitous", (checkoutInfo?.plan?.trial.interval + " " + t.frequency_interval_unit[checkoutInfo?.plan?.trial.interval_unit]))
			}
		} else {
			return toCurrency(success_info.amount) + " " + t("^titles.trial", (checkoutInfo?.plan?.trial.interval + " " + t.frequency_interval_unit[checkoutInfo?.plan?.trial.interval_unit]))

		}
	}

	const tableData = [
		{
			label: `Número do pedido:`,
			value: checkoutInfo.data.order_id,
			boldValue: true,
			boldLabel: true,
		},
		{
			label: t.checkout.total_amount,
			value: toCurrency(pixData.amount, "BRL"),
			boldValue: true,
			boldLabel: true,
		},
		(userInfo.name || userInfo.first_name)
			? {
				label: `${t.titles.customer}:`,
				value: userInfo.name || userInfo.first_name + " " + userInfo.last_name,
				boldValue: true,
				boldLabel: true
			}
			: null,
		(userInfo.email)
			? {
				label: `${t.checkout.email}:`,
				value: userInfo.email,
				boldValue: true,
				boldLabel: true
			}
			: null,
	]

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return (
		<DivRoot>
			<div
				style={{
					width: "100%",
					backgroundColor: primaryColor,
					padding: 5,
					marginBottom: 40,
				}}
			>
				<DivLogo >
					<img src={checkoutInfo.template.style.images.logo} style={{ height: '100%' }} />
				</DivLogo>
			</div>

			<div className="flex-row center-a">
				<div
					className="flex-col center-b"
					style={{
						borderRadius: 25,
						border: "1px solid rgba(0,0,0,0.19)",
						width: "80vw",
						padding: "30px 15px",
						maxWidth: "1440px",
						margin: 40,
						marginTop: 0,
					}}
				>
					<div className="flex-col center-b" style={{ width: "fit-content" }}>
						<Icon
							icon="mdi:success-circle"
							color="#09b64e"
							width="100"
							height="100"
						/>

						<div position="center">
							<Title align="center">
								<span style={{ fontSize: "1.5rem" }}>
									{pixData.amount > 0
										? t.success.pix_created
										: isProduct
											? t.products.success_page_title
											: "Pagamento concluído com sucesso!"}
								</span>
							</Title>
						</div>
						{pixData.amount > 0 && (
							<>
								<div position="center">
									<Title align="center">
										<span style={{ fontSize: "1rem" }}>
											{!isProduct && t.success.pay_to_activate}
										</span>
									</Title>
								</div>

								<div>
									<QRCode data={pixData.extra} />
								</div>
							</>
						)}
						<br></br>
						<div>
							<Title align="center">
								<h3 style={{ margin: 0 }}>{t("^currencies.amount")}:</h3>
								<h2 style={{ margin: 0 }}>{toCurrency(pixData.amount, "BRL")}</h2>
							</Title>
						</div>
						<br />
					</div>

					{pixData.amount > 0 && (
						<div className="flex-col center-b" style={{ width: "80%" }}>
							<div style={{ width: "100%", marginTop: 15 }}>
								<InputCode value={pixData.payment_number} id="pix-code"></InputCode>
							</div>
							<SectionMain position="center" direction="column" alignItems="center">
								<div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
									<StyledButton
										onClick={copyCheckoutLinkToClipboard}
										variant="text"
										startIcon={<FileCopyIcon htmlColor={primaryColor} />}
									>
										{t.checkout.copy_code}
									</StyledButton>
								</div>
							</SectionMain>
							<br />
						</div>
					)}
					<div className="flex-col center-b" style={{ width: "fit-content" }}>
						<CompactTable
							data={tableData}
							noCommas
							noTitle
							tableStyle={{ width: "100%", margin: "15px 0 0 0" }}
							rowStyle={{ color: "rgba(0, 0, 0, 0.8)" }}
						/>

						<SectionMain position="center" direction="column" alignItems="center">
							<span style={{ fontSize: 18, maxWidth: 300, textAlign: "center" }}>
								{t("^messages.send_invoice.subscription", userInfo.email)}
							</span>
						</SectionMain>
					</div>
				</div>
			</div>
		</DivRoot>
	)
}

export default SuccessPix
